import { CommonCompenentDataMarket, CompenentDataMarketFirstSecondExtraTime, CompenentDataMarketDefault, CompenentDataMarketDynamic } from "./GlobalComponents"


export const orderLoop = ["first_home_bet", "second_home_bet", "third_home_bet", "fourth_home_bet"];
export const urlSurebetLive = "https://arbitragem.vps.webdock.cloud/live_surebet";
export const linkChangePlan = '/renovacao';
export const linkWhatsapp = "https://wa.me/message/J6E5GFQRTGTFE1";
export const listSortType = ['Lucro', 'Início', 'Recentes'];
export const listSortTypeToSelect = { 'início': 'Início', 'recentes': 'Recentes' };

export const NameBetHomeRenamed = {
  "betano(br)": "Betano",
  "vbet(lat)": "Vbet",
  "bodog(en)": "Bodog",
}

export const managerClonerBetHome = {
  'allBetHomeAbleToClone': [
    "sportingbet",
    "betnacional",
    "estrelabet",
    "pixbet",
    "bolsadeaposta",
    "pinnacle",
    'vaidebet',
    'vbet (lat)',
    'betfast',
    "kto",
  ],

  'sportingbet': {
    'allBetHomeClone': ['betboo'],
    'betboo': {
      'baseURL': 'https://sports.br.betboo.com',
      'nameBetHome': 'Betboo',
    }
  },
  'betnacional': {
    'allBetHomeClone': ['mrjack'],
    'mrjack': {
      'baseURL': 'https://mrjack.bet',
      'nameBetHome': 'MrJack',
    },
  },
  'estrelabet': {
    'allBetHomeClone': ['kto'],
    'kto': {
      'baseURL': 'https://www.kto.com',
      'nameBetHome': 'KTO',
    },
  },
  'pixbet': {
    'allBetHomeClone': ['flabet', 'ganhabet', 'betvip'],
    'flabet': {
      'baseURL': 'https://www.flabet.com/sportsbook?',
      'nameBetHome': 'Flabet',
    },
    'ganhabet': {
      'baseURL': 'https://ganhabet.com/sports?',
      'nameBetHome': 'Ganhabet',
    },
    'betvip': {
      'baseURL': 'https://betvip.com/sports?',
      'nameBetHome': 'Betvip',
    },
  },
  'bolsadeaposta': {
    'allBetHomeClone': ['bet-bra', 'fulltbet'],
    'bet-bra': {
      'baseURL': 'https://bet-bra.com',
      'nameBetHome': 'Bet-Bra',
    },
    'fulltbet': {
      'baseURL': 'https://fulltbet.com',
      'nameBetHome': 'FulltBet',
    },

  },

  'pinnacle': {
    'allBetHomeClone': ['bolsadeaposta sb', 'bet-bra sb', 'fulltbet sb'],
    'bolsadeaposta sb': {
      'baseURL': 'https://sports.bolsadeaposta.com',
      'nameBetHome': 'BolsaDeAposta SB',
    },
    'bet-bra sb': {
      'baseURL': 'https://sports2.bet-bra.com',
      'nameBetHome': 'Bet-Bra SB',
    },
    'fulltbet sb': {
      'baseURL': 'https://sports.fulltbet.com',
      'nameBetHome': 'FulltBet SB',
    },
  },

  'vaidebet': {
    'allBetHomeClone': ['betpix365', 'esportesdasorte'],
    'betpix365': {
      'baseURL': 'https://betpix365.com',
      'nameBetHome': 'BetPix365',
    },
    'esportesdasorte': {
      'baseURL': 'https://www.esportesdasorte.com',
      'nameBetHome': 'EsportesDaSorte',
    },
  },
  'vbet (lat)': {
    'allBetHomeClone': ['playpix', 'segurobet', 'dupoc', '7games', 'seubet'],
    'playpix': {
      'baseURL': 'https://www.playpix.com',
      'nameBetHome': 'Playpix',
    },
    'segurobet': {
      'baseURL': 'https://www.segurobet.com/pre-jogo/match',
      'nameBetHome': 'Segurobet',
    },
    'dupoc': {
      'baseURL': 'https://www.dupoc.com/pb/sport',
      'nameBetHome': 'Dupoc',
    },
    '7games': {
      'baseURL': 'https://7games.bet.br/pb/sports/pre-match',
      'nameBetHome': '7games',
    },
    'seubet': {
      'baseURL': 'https://www.seubet.com/en/prematch/match',
      'nameBetHome': 'Seubet',
    },

  },
  'betfast': {
    'allBetHomeClone': ['faz1bet', 'tivobet'],
    'faz1bet': {
      'baseURL': 'https://faz1bet.com/br#/',
      'nameBetHome': 'Faz1bet',
    },
    'tivobet': {
      'baseURL': 'https://tivo.bet/br/sportsbook/prematch#/',
      'nameBetHome': 'Tivobet',
    }
  },
  'kto': {
    'allBetHomeClone': ['estrelabet'],
    'estrelabet': {
      'baseURL': 'https://estrelabet.com/pb/esportes#/sport/145/category/1533/championship/12345/event',
      'nameBetHome': 'EstrelaBet',
    },
  },
}


export const listCloneBetHomeModeLive = {
  'vbet (lat)': { 
    'segurobet': {
      'baseURL': 'https://www.segurobet.com/ao-vivo/match',
      'nameBetHome': 'Segurobet',
    },
    'dupoc': {
      'baseURL': 'https://www.dupoc.com/pb/live',
      'nameBetHome': 'Dupoc',
    },
    '7games': {
      'baseURL': 'https://www.7games.bet/pb/sports/live/event-view',
      'nameBetHome': '7games',
    },
    'seubet': {
      'baseURL': 'https://www.seubet.com/en/live/match',
      'nameBetHome': 'Seubet',
    },
  },
  'kto': {
    'estrelabet': {
      'baseURL': 'https://estrelabet.com/pb/esportes#/sport/146/category/1728/championship/12345/liveEvent',
      'nameBetHome': 'EstrelaBet',
    },
  },
}
export const listBetHomeDemarkOnFilter = (managerClonerBetHome['allBetHomeAbleToClone'].map(item => {
  let listBetHome = managerClonerBetHome[item]['allBetHomeClone'];
  listBetHome.push(item);
  return listBetHome;
}).concat(["sportingbet", "betboo", "bwin"]));
export const styleDataMarket = {

  'common_data_market': {
    'componentFunction': function (infoDataMarket, description) {

      return (
        <CommonCompenentDataMarket
          infoDataMarket={infoDataMarket}
          description={description}
        />
      )
    }
  },
  'first_second_extra_time': {
    'componentFunction': function (infoDataMarket, description) {

      return (
        <CompenentDataMarketFirstSecondExtraTime
          infoDataMarket={infoDataMarket}
          description={description}
        />
      )
    }
  },

  'default_data_market': {
    'componentFunction': function (infoDataMarket, description) {

      return (
        <CompenentDataMarketDefault
          infoDataMarket={infoDataMarket}
          description={description}
        />
      )
    },
  },

  'dynamic_market': {
    'componentFunction': function (infoDataMarket, description) {

      return (
        <CompenentDataMarketDynamic
          infoDataMarket={infoDataMarket}
          description={description}
        />
      )
    },
  }
}


export const timeSurebetAllowed = 900000;

//------📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱CALCULATOR📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱

export const partInfoOddSurebetJSON = '{"percent":0,"typeBet":"normal"}';


export const modelPopUpSettings = {
  'popupWindowAll': {
    'width': 800,
    'height': 460,
    'top': 0,
    'left': 0,
  },
  'popupWindowAll2': {
    'width': 800,
    'height': 460,
    'top': 0,
    'left': 0,
  },
}