import LayoutSurebetMobile from "./LayoutSurebetMobile";
import { NameBetHomeRenamed, styleDataMarket } from "../global/GlobalVariables";
import { useMediaQuery } from 'react-responsive';
import Dropdown from 'react-bootstrap/Dropdown';
import GeneratorLinkSurebet from "./functions/GeneratorLinkExtension";
let orderloop = ["first_home_bet", "second_home_bet", "third_home_bet", "fourth_home_bet"];
const estrela = `${process.env.PUBLIC_URL}/images/icone-etoile-verte.png`;


function ComponentSurebet(props) {

    const {
        data,
        value,
        index,
        statusMode,
        eventTrash,
        openPopUpCalculator,
        newKeySymbol,
        statusTrash,
        iconelixeira,
        rowRef,
        handleClick,
        openPopUpBet,
        warningTimeSurebet,
        eventTrashAllGamesEventsSelected
    } = props;

    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
    function orderComponent(dataSB, value) {
        let all_bh = {};
        let allDateTime = {};
        let allTitleEvent = {};
        let allDataMarket = {};
        let allChance = {};


        for (let indexOrder = 0; indexOrder < orderloop.length; indexOrder++) {
            let nameOrder = orderloop[indexOrder];

            if (nameOrder in dataSB[value]['main']) {
                let urlExt = GeneratorLinkSurebet(dataSB[value]["main"][nameOrder], dataSB[value]["main"]['profit']);

                let nameBetHomeModified = dataSB[value]['main'][nameOrder]['name_bet_home'].toLowerCase().replaceAll(' ', '');
                let nameBetHomeToImplement = nameBetHomeModified in NameBetHomeRenamed ? NameBetHomeRenamed[nameBetHomeModified] : dataSB[value]['main'][nameOrder]['name_bet_home'];

                all_bh[nameOrder] = (
                    <div className="d-flex flex-column ">
                        <div className={`link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover ${nameOrder === 'first_home_bet' ? ' paddingTopMibile' : ''} ${statusMode ? "colorLink" : ""} default-font-size`}
                            onClick={() => {


                                openPopUpBet(dataSB[value]['main'][nameOrder]['url_bet_home']);

                            }}
                            role="button"
                            style={{ whiteSpace: "pre" }}
                        >
                            {nameBetHomeToImplement}
                        </div>

                        <span className={`m-0 legenda-2 ${statusMode ? "textGray1" : "text-black-50"} default-small-font-size`}>
                            {dataSB[value]['main'][nameOrder]['sport']}
                        </span>
                    </div>
                );


                allDateTime[nameOrder] = (
                    <div className={`d-flex flex-column text-center justify-content-center align-items-center  ${statusMode ? "textGray2" : ""} `}>
                        <span className="fontSizeDefaultDevice default-font-size" >{dataSB[value]['main'][nameOrder]['date_event']}</span>

                        <span className={`badge rounded-pill ${statusMode ? "textDark2" : "text-light"} ${(warningTimeSurebet.includes(value)) === true ? 'bg-danger' : 'bg-success'} padding-time-badge default-small-font-size`} >{dataSB[value]['main'][nameOrder]['time_event']}</span>
                    </div>
                )

                allTitleEvent[nameOrder] = (
                    <div className={`d-flex flex-column `}>

                        <div className={`text-decoration-underline link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover ${statusMode ? "colorLink" : ""} default-font-size`}
                            onClick={() => {
                                openPopUpBet(urlExt);
                            }
                            }
                            role="button"
                        >
                            {dataSB[value]['main'][nameOrder]['title_event']}
                        </div>

                        <span className={`m-0 legenda-2 ${statusMode ? "textGray1" : "text-black-50"} default-small-font-size`}>
                            {dataSB[value]['main'][nameOrder]['sub_title_event']}
                        </span>

                    </div>
                );

                allDataMarket[nameOrder] = (


                    dataSB[value]['main'][nameOrder].hasOwnProperty('description_data_market') ?
                        <p className={`${statusMode ? "textGray2" : ""} default-font-size`}>
                            {styleDataMarket[dataSB[value]['main'][nameOrder]['data_market']['type']]['componentFunction'](dataSB[value]['main'][nameOrder]['data_market'], dataSB[value]['main'][nameOrder]['description_data_market'])}

                        </p>
                        :

                        <div className={`d-flex flex-column d-flex flex-column justify-content-center h-100`}>
                            <p
                                className={`m-0 ${statusMode ? "textGray2" : ""} default-font-size data_market2`}
                                dangerouslySetInnerHTML={{ __html: dataSB[value]['main'][nameOrder]['data_market'].replace("<abbr", '<abbr  class="title"  style="text-decoration:auto;"').replace("minorc", "legenda-2").replace("minor", "legenda-2 text-secondary").replace("<sup", '<sup class="legenda-2"') }}
                            />
                        </div>

                );

                allChance[nameOrder] = (
                    <div className={`d-flex flex-column d-flex flex-column `}>







                        <a href={urlExt} target="_blank" rel="noreferrer" className={`link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover ${statusMode ? "colorLink" : ""} default-font-size`}>
                            {dataSB[value]['main'][nameOrder]['chance']}
                        </a>

                    </div>
                );
            }


        }


        let allElementsFragmentes = {
            'containerBetHome': all_bh,
            'containerDateTime': allDateTime,
            'containerTitleEvent': allTitleEvent,
            'containerDataMarket': allDataMarket,
            'containerChance': allChance,
        }

        return allElementsFragmentes;
    }

    let containerSurebet = orderComponent(data, value);
    return (
        <>
            {
                !isMobile ?
                    <div className={`layout-mobile-desktop-and-tablet ${(index % 2 === 1) ? statusMode ? "bg-sb-dark" : "bg-sb" : ""}`} ref={(el) => {
                        rowRef.current = el;
                    }}>
                        <div className={`w-100`}>
                            <div className={`profit-mobile  d-flex justify-content-between ${statusMode ? "backgroundProfitMobileDark" : index % 2 === 0 ? "backgroundProfitMobileLight" : "backgroundProfitMobileLight2"}`}>
                                <div className="d-flex align-items-end ps-2">
                                    <p className="m-0 text-success fw-bold fs-5 profit text-center" style={{ whiteSpace: "break-spaces" }}>
                                        {newKeySymbol.includes(value) ?
                                            <>{data[value]['main']['profit']} <sup className="text-warning">New</sup></> :
                                            <>{data[value]['main']['profit']}</>
                                        }
                                    </p>

                                    <div className="d-flex align-items-end">
                                        <span
                                            className={`ps-1 m-0 legenda ${statusMode ? "textGray1" : "text-black-50"} fontSizeDefaultDevice`}>
                                            {data[value]['main']['time']}
                                        </span>

                                        <>
                                            {


                                                data[value]['main']["first_home_bet"].hasOwnProperty('event_id') ?
                                                    <img
                                                        src={estrela}
                                                        alt="Logo"
                                                        className="logo-star"
                                                    />                                                    
                                                    :
                                                    <div className="empty-space-logo"></div>
                                            }
                                        </>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center pe-2">
                                    <button
                                        className="p-0 mx-1 btn btn-warning text-decoration-none d-flex justify-content-center align-items-center defaultSizeBtnTrashCalculatorDevice"
                                        title="Calculadora de aposta segura"

                                        onClick={() => { openPopUpCalculator(value); }}
                                        style={{ width: "30px", height: "30px" }}
                                    >
                                        <svg
                                            className="iconCalculator"
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            version="1"
                                            viewBox="0 0 48 48"
                                            enableBackground="new 0 0 48 48"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ width: "30px", height: "30px" }}
                                        >
                                            <path fill="#616161" d="M40,16H8v24c0,2.2,1.8,4,4,4h24c2.2,0,4-1.8,4-4V16z"></path>
                                            <path fill="#424242" d="M36,4H12C9.8,4,8,5.8,8,8v9h32V8C40,5.8,38.2,4,36,4z"></path>
                                            <path fill="#9CCC65" d="M36,14H12c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1h24c0.6,0,1,0.4,1,1v5C37,13.6,36.6,14,36,14z"></path>
                                            <g fill="#33691E">
                                                <rect x="33" y="10" width="2" height="2"></rect>
                                                <rect x="29" y="10" width="2" height="2"></rect>
                                            </g>
                                            <path fill="#FF5252" d="M36,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,22.6,36.6,23,36,23z"></path>
                                            <g fill="#E0E0E0">
                                                <path d="M15,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,22.6,15.6,23,15,23z"></path>
                                                <path d="M22,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,22.6,22.6,23,22,23z"></path>
                                                <path d="M29,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,22.6,29.6,23,29,23z"></path>
                                                <path d="M15,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,28.6,15.6,29,15,29z"></path>
                                                <path d="M22,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,28.6,22.6,29,22,29z"></path>
                                                <path d="M29,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,28.6,29.6,29,29,29z"></path>
                                                <path d="M15,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,34.6,15.6,35,15,35z"></path>
                                                <path d="M22,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,34.6,22.6,35,22,35z"></path>
                                                <path d="M29,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,34.6,29.6,35,29,35z"></path>
                                                <path d="M15,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,40.6,15.6,41,15,41z"></path>
                                                <path d="M22,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,40.6,22.6,41,22,41z"></path>
                                                <path d="M29,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,40.6,29.6,41,29,41z"></path>
                                            </g>
                                            <g fill="#BDBDBD">
                                                <path d="M36,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,28.6,36.6,29,36,29z"></path>
                                                <path d="M36,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,34.6,36.6,35,36,35z"></path>
                                                <path d="M36,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,40.6,36.6,41,36,41z"></path>
                                            </g>
                                        </svg>
                                    </button>
                                    <Dropdown data-bs-theme="dark" >
                                                <Dropdown.Toggle split id="dropdown-basic" className={`all_trash p-0  d-flex justify-content-center align-items-center btn ${statusTrash ? 'btn-primary' : 'btn-danger'} defaultSizeBtnTrashCalculatorDevice`} style={{ width: "30px", height: "30px" }}

                                                >
                                                    {statusTrash ? (
                                                        <>
                                                            <img
                                                                className="img-listagem-voltar"
                                                                style={{
                                                                    filter: "invert(100%)",
                                                                    width: 16,
                                                                    height: 16,
                                                                }}
                                                                src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                                                                alt="left2"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                className="img-listagem"
                                                                width="25"
                                                                height="25"
                                                                src={iconelixeira}
                                                                alt="delete"
                                                            />
                                                        </>
                                                    )}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="border border-light p-0 m-0" >

                                                    {statusTrash ?
                                                        <>
                                                            <Dropdown.Item className="rounded fw-bold style-fs-btn-delete-restore" onClick={() => eventTrash(value, statusTrash)}>RESTAURAR APOSTA</Dropdown.Item>
                                                            <hr className="text-light fs-1 p-0 m-0" />
                                                            <Dropdown.Item className="rounded fw-bold style-fs-btn-delete-restore" onClick={() => eventTrashAllGamesEventsSelected(value)}>RESTAURAR PARTIDA</Dropdown.Item>
                                                        </>
                                                        :
                                                        <>
                                                            <Dropdown.Item className="rounded fw-bold style-fs-btn-delete-restore" onClick={() => eventTrash(value, statusTrash)}>EXCLUIR APOSTA</Dropdown.Item>
                                                            <hr className="text-light fs-1 p-0 m-0" />
                                                            <Dropdown.Item className="rounded fw-bold style-fs-btn-delete-restore" onClick={() => eventTrashAllGamesEventsSelected(value)}>EXCLUIR PARTIDA</Dropdown.Item>
                                                        </>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                </div>

                                    
                            </div>

                            <div className="w-100 area-sb pt-1" key={index}>


                                <div className="p-0 area-profit area-profit-desktop">
                                    <div className={`area-profit-desktop`}>
                                        <div className="d-flex flex-column align-items-center">
                                            {
                                                newKeySymbol.includes(value) ?
                                                    <p className="m-0 text-success fw-bold fs-5 profit r text-profit ">
                                                        <><span className="fs-6 text-warning p-0 m-0 text-end w-100 d-new-0">New</span> <div>  {data[value]['main']['profit']} <sup className="text-warning d-new-1">New</sup></div></>
                                                    </p>
                                                    :
                                                    <p className="m-0 text-success fw-bold fs-5 profit text-center" style={{ whiteSpace: "break-spaces" }}>
                                                        <>{data[value]['main']['profit']}</>
                                                    </p>

                                            }

                                            <div className="d-flex flex-column align-items-center">
                                                <span
                                                    className={`ps-1 m-0 legenda ${statusMode ? "textGray1" : "text-black-50"} default-small-font-size`} >
                                                    {data[value]['main']['time']}
                                                </span>
                                                {

                                                    data[value]['main']["first_home_bet"].hasOwnProperty('event_id') ?
                                                        <div>
                                                            <img
                                                                src={estrela}
                                                                alt="Logo"
                                                                className="logo-star"
                                                            />
                                                        </div>

                                                        :
                                                        <div className="empty-space-logo"></div>
                                                }
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="p-0 mx-1 btn btn-warning text-decoration-none d-flex justify-content-center align-items-center defaultSizeBtnTrashCalculatorDevice"
                                                title="Calculadora de aposta segura"

                                                onClick={() => { openPopUpCalculator(value); }}
                                                style={{ width: "25px", height: "25px" }}
                                            >
                                                <svg
                                                    className="iconCalculator"
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    strokeWidth="0"
                                                    version="1"
                                                    viewBox="0 0 48 48"
                                                    enableBackground="new 0 0 48 48"
                                                    height="1em"
                                                    width="1em"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{ width: "25px", height: "25px" }}
                                                >
                                                    <path fill="#616161" d="M40,16H8v24c0,2.2,1.8,4,4,4h24c2.2,0,4-1.8,4-4V16z"></path>
                                                    <path fill="#424242" d="M36,4H12C9.8,4,8,5.8,8,8v9h32V8C40,5.8,38.2,4,36,4z"></path>
                                                    <path fill="#9CCC65" d="M36,14H12c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1h24c0.6,0,1,0.4,1,1v5C37,13.6,36.6,14,36,14z"></path>
                                                    <g fill="#33691E">
                                                        <rect x="33" y="10" width="2" height="2"></rect>
                                                        <rect x="29" y="10" width="2" height="2"></rect>
                                                    </g>
                                                    <path fill="#FF5252" d="M36,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,22.6,36.6,23,36,23z"></path>
                                                    <g fill="#E0E0E0">
                                                        <path d="M15,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,22.6,15.6,23,15,23z"></path>
                                                        <path d="M22,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,22.6,22.6,23,22,23z"></path>
                                                        <path d="M29,23h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,22.6,29.6,23,29,23z"></path>
                                                        <path d="M15,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,28.6,15.6,29,15,29z"></path>
                                                        <path d="M22,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,28.6,22.6,29,22,29z"></path>
                                                        <path d="M29,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,28.6,29.6,29,29,29z"></path>
                                                        <path d="M15,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,34.6,15.6,35,15,35z"></path>
                                                        <path d="M22,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,34.6,22.6,35,22,35z"></path>
                                                        <path d="M29,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,34.6,29.6,35,29,35z"></path>
                                                        <path d="M15,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C16,40.6,15.6,41,15,41z"></path>
                                                        <path d="M22,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C23,40.6,22.6,41,22,41z"></path>
                                                        <path d="M29,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C30,40.6,29.6,41,29,41z"></path>
                                                    </g>
                                                    <g fill="#BDBDBD">
                                                        <path d="M36,29h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,28.6,36.6,29,36,29z"></path>
                                                        <path d="M36,35h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,34.6,36.6,35,36,35z"></path>
                                                        <path d="M36,41h-3c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h3c0.6,0,1,0.4,1,1v2C37,40.6,36.6,41,36,41z"></path>
                                                    </g>
                                                </svg>
                                            </button>
                                            <Dropdown data-bs-theme="dark" >
                                                <Dropdown.Toggle split id="dropdown-basic" className={`all_trash p-0  d-flex justify-content-center align-items-center btn ${statusTrash ? 'btn-primary' : 'btn-danger'} defaultSizeBtnTrashCalculatorDevice`} style={{ width: "25px", height: "25px" }}

                                                >
                                                    {statusTrash ? (
                                                        <>
                                                            <img
                                                                className="img-listagem-voltar"
                                                                style={{
                                                                    filter: "invert(100%)",
                                                                    width: 16,
                                                                    height: 16,
                                                                }}
                                                                src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                                                                alt="left2"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                className="img-listagem"
                                                                width="25"
                                                                height="25"
                                                                src={iconelixeira}
                                                                alt="delete"
                                                            />
                                                        </>
                                                    )}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="border border-light p-0 m-0" >

                                                    {statusTrash ?
                                                        <>
                                                            <Dropdown.Item className="rounded fw-bold style-fs-btn-delete-restore" onClick={() => eventTrash(value, statusTrash)}>RESTAURAR APOSTA</Dropdown.Item>
                                                            <hr className="text-light fs-1 p-0 m-0" />
                                                            <Dropdown.Item className="rounded fw-bold style-fs-btn-delete-restore" onClick={() => eventTrashAllGamesEventsSelected(value)}>RESTAURAR PARTIDA</Dropdown.Item>
                                                        </>
                                                        :
                                                        <>
                                                            <Dropdown.Item className="rounded fw-bold style-fs-btn-delete-restore" onClick={() => eventTrash(value, statusTrash)}>EXCLUIR APOSTA</Dropdown.Item>
                                                            <hr className="text-light fs-1 p-0 m-0" />
                                                            <Dropdown.Item className="rounded fw-bold style-fs-btn-delete-restore" onClick={() => eventTrashAllGamesEventsSelected(value)}>EXCLUIR PARTIDA</Dropdown.Item>
                                                        </>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        

                                    </div>
                                </div>

                                <div className="info-surebet-grid">

                                    {



                                        orderloop.map((nameOrder, indexOrder) => {
                                            if (nameOrder in data[value]['main']) {
                                                return (
                                                    <div className="d-flex align-items-center" key={indexOrder}>
                                                        <div className="area-bet-home d-flex flex-column justify-content-around">
                                                            {Object.values(containerSurebet['containerBetHome'])[indexOrder]}
                                                        </div>


                                                        <div className="area-date-time d-flex flex-column justify-content-around">
                                                            {Object.values(containerSurebet['containerDateTime'])[indexOrder]}
                                                        </div>
                                                        <div className="area-event d-flex flex-column justify-content-around">
                                                            {Object.values(containerSurebet['containerTitleEvent'])[indexOrder]}
                                                        </div>
                                                        <div className="area-data-market d-flex flex-column justify-content-around">
                                                            {Object.values(containerSurebet['containerDataMarket'])[indexOrder]}
                                                        </div>
                                                        <div className="area-chance d-flex flex-column justify-content-around">
                                                            {Object.values(containerSurebet['containerChance'])[indexOrder]}
                                                        </div>


                                                    </div>
                                                )
                                            }
                                            else {
                                                return ""
                                            }
                                        })
                                    }

                                </div>



                                <div className="area-redirect d-flex justify-content-end">

                                    <div id="openAllodds" className="rounded-start py-4 greenArbitragem openAllodds d-flex align-items-center open-all-odds"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {

                                            handleClick(value);
                                        }}>

                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 6 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ width: "100%", height: "auto" }}>
                                            <path fillRule="evenodd" fill={`${statusMode ? "#0d0d0d" : "#FFF"}`} d="M0 14l6-6-6-6v12z"></path>
                                        </svg>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                    :
                    <div className={`layout-mobile-cell-phone ${(index % 2 === 1) ? "bg-surebet-2" : ""}`} ref={(el) => {
                        rowRef.current = el;
                    }}>
                        <LayoutSurebetMobile
                            data={data}
                            value={value}
                            statusMode={statusMode}
                            handleClick={handleClick}
                            newKeySymbol={newKeySymbol}
                            eventTrash={eventTrash}
                            statusTrash={statusTrash}
                            warningTimeSurebet={warningTimeSurebet}
                            eventTrashAllGamesEventsSelected={eventTrashAllGamesEventsSelected}
                        />
                    </div>

            }

        </>
    )
}


export default ComponentSurebet;