import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Routes, Route } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../assets/css/HeaderWebsite.css";
import AuthenticationForm from "./AuthenticationForm";
import RedeSociaisComponent from "./RedeSociaisComponent";
import HomePage from "./HomePage";
import Pagamentos from "./PageDeCompraDoPlano";
import LandingPage from "./LandingPage";

import Afilhado from "./Afilhado";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { linkWhatsapp } from "../global/GlobalVariables";
import "../assets/css/SubNavbar.css";

Modal.setAppElement("#root");

const HeaderPagamentos = (props) => {
  const {
    setIsLoggedIn,
    statusMode,
    setStatusMode,
    onLogout,
    isLoggedIn,
    showLogoutButton,
    handleLoginSuccess,
    userData,
    user,
    setIdinitialization,
    setAmountinitialization,
    Idinitialization,
    DateMsg,
    setDateMsg,
    setUserData,
  } = props;

  const navigate = useNavigate();
  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      const loadUsers = () => {
        axios
          .post("https://arbitragem.bet:8081/login", {
            email: userData.email,
            senha: userData.senha,
          })
          .then((response) => {
            const dado = response.data;
            if (dado) {
              console.log("Status da Conta:", dado.status_da_conta);
              if (dado.status_da_conta !== "Inativo") {
                // Set userData and navigate
                setUserData(response.data);
                navigate("/");
                // Reload the page
                window.location.reload();
              }
            }
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      };

      loadUsers();
    }, 1000);
    return () => clearInterval(fetchDataInterval);
  }, [userData]);

  const wpplogo = `${process.env.PUBLIC_URL}/svgimagens/icons8-whatsapp-96.png`;
  const instalogo = `${process.env.PUBLIC_URL}/svgimagens/icons8-instagram-96.png`;
  const youtlogo = `${process.env.PUBLIC_URL}/svgimagens/icons8-reproduzir-youtube-96.png`;
  const wpplogop = `${process.env.PUBLIC_URL}/svgimagens/icons8-whatsapp-96-preto.png`;
  const instalogop = `${process.env.PUBLIC_URL}/svgimagens/icons8-instagram-96-preto.png`;
  const youtlogop = `${process.env.PUBLIC_URL}/svgimagens/icons8-reproduzir-youtube-96-preto.png`;
  const logobranca = `${process.env.PUBLIC_URL}/LOGOBRANCO.png`;
  const imgbghome = `${process.env.PUBLIC_URL}/images/bghome.png`;

  // Inicializa a variável com true para mostrar o cabeçalho
  const [showHeader, setShowHeader] = useState(true);
  const { pathname } = useLocation();

  const shouldShowLogoutButton = () => {
    const hash = window.location.hash;

    // Verifica se a rota atual é exatamente "/" ou não possui hash (caso padrão)
    if (hash === "" || hash === "/") {
      return true;
    }

    // Verifica se a rota atual contém "/calculator/"
    if (hash.includes("/calculator/")) {
      return false;
    }

    // Obtenha a rota atual
    const currentPath = window.location.pathname;

    // Registre a rota atual no console

    // Verifique se a rota atual é exatamente "/"
    if (currentPath === "/") {
      return true;
    }

    // Verifique se a rota atual contém "/calculator/"
    if (currentPath.includes("/calculator/")) {
      return false;
    }

    // Em todos os outros casos, mostra o botão de logout

    return true;
  };

  const [volume, setVolume] = useState(0.5);
  // Função que verifica se a rota é "/logar"
  const isLogar = () => {
    const hash = window.location.hash;
    return hash === "/logar" || hash === "/curso";
  };

  // Função para alternar a exibição do cabeçalho
  const toggleHeader = () => {
    setShowHeader(!isLogar());
  };
  const changeStatusMode = (oldStatusMode) => {
    setStatusMode(!oldStatusMode);

    window.localStorage.setItem("statusMode", JSON.stringify(!oldStatusMode));
    const dataToJson = JSON.stringify(!oldStatusMode);
    localStorage.setItem("statusMode", dataToJson);
    !oldStatusMode
      ? (document.body.style.backgroundColor = "#0d0d0d")
      : (document.body.style.backgroundColor = "");
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;

      // Verifica se a URL atual não é "/logar"
      if (hash !== "/logar" || hash !== "/apostas") {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    // Registre o ouvinte de evento para mudanças de hash
    window.addEventListener("hashchange", handleHashChange);

    // Certifique-se de chamar handleHashChange para verificar a URL inicial
    handleHashChange();

    // Remova o ouvinte de evento ao desmontar o componente
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);
  const handleVoltarClick = () => {
    sessionLogout();
  };
  // Função para enviar os dados para o servidor
  const sessionLogout = async () => {
    try {
      // Envia uma solicitação POST para a rota de logout no servidor
      const response = await axios.post(
        "https://arbitragem.bet:8081/api/logout",
        {
          // Coloque o ID do usuário que deseja fazer logout
          // Aqui, supomos que você tenha a variável userData que contém o ID do usuário
          userID: userData.id,
        }
      );

      // Trate a resposta do servidor, se necessário
      // Por exemplo, você pode verificar a resposta para saber se o logout foi bem-sucedido
      if (response.status === 200) {
        window.localStorage.setItem("login", JSON.stringify(false));

        setIsLoggedIn(false);

        navigate("/");
      } else {
        console.log("Logout falhou");
      }
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
    }
  };
  return (
    <div
      className="App Initial-Control-Page"
      style={{ backgroundImage: `url(${imgbghome})` }}
    >
      {/* Condição para renderizar ou não o cabeçalho */}
      {showHeader === false ? (
        <div>
          <AuthenticationForm
            statusMode={statusMode}
            onLoginSuccess={handleLoginSuccess}
            showHeader={showHeader}
            setShowHeader={setShowHeader}
          />
        </div>
      ) : (
        <div>
          <>
            {["lg"].map((expand) => (
              <Navbar
                key={expand}
                expand={expand}
                className={`bg-body-tertiary mb-3 header d-flex justify-content-between align-items-center navbar navbar-expand-lg navbar-dark mb-3 ResponsivHeader ${
                  statusMode ? "dark-mode" : ""
                }`}
              >
                <Container fluid>
                  <Navbar.Brand href="#">
                    <div className=" p-2 fw-semibold d-flex align-items-center">
                      <a
                        className={`text-decoration-none fs-5 me-3 ${
                          statusMode ? "textDark" : "text-light"
                        }`}
                        href="/"
                      >
                        <img
                          src={logobranca}
                          alt="Logo"
                          style={{ height: "55px", width: "auto" }}
                          className="imgfullhd"
                        />
                      </a>
                    </div>
                  </Navbar.Brand>
                  <Navbar.Toggle
                    aria-controls={`offcanvasNavbar-expand-${expand}`}
                    className="margin20px"
                  />
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title
                        id={`offcanvasNavbarLabel-expand-${expand}`}
                      >
                        <a
                          className={`text-decoration-none fs-5 me-3 ${
                            statusMode ? "textDark" : "text-light"
                          }`}
                          href="/"
                        >
                          <img
                            src={logobranca}
                            alt="Logo"
                            style={{ height: "55px", width: "auto" }}
                            className="imgfullhd"
                          />
                        </a>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      {/* Inicio do itens do social*/}
                      <div className="social">
                        <a
                          href="https://www.instagram.com/arbitragem.bet/"
                          target="_blank"
                          rel="noreferrer"
                          className="social-link"
                        >
                          <img
                            src={instalogo}
                            alt="Instagram"
                            className="social-icon insta"
                          />
                        </a>
                        <div className="space-icon"></div>
                        <a
                          href="https://www.youtube.com/channel/UC1cr8xyol7AsWOqRj6iK9Pw"
                          target="_blank"
                          rel="noreferrer"
                          className="social-link"
                        >
                          <img
                            src={youtlogo}
                            alt="YouTube"
                            className="social-icon youtube"
                          />
                        </a>
                        <div className="space-icon"></div>
                        <a
                          href={linkWhatsapp}
                          target="_blank"
                          rel="noreferrer"
                          className="social-link"
                        >
                          <img
                            src={wpplogo}
                            alt="WhatsApp"
                            className="social-icon"
                          />
                        </a>
                      </div>
                      {/* Fim do itens do social*/}
                      <div className="menu mudanca-menu">
                        <ul className={`menu-links text-light `}>
                          <a
                            href={linkWhatsapp}
                            target="_blank"
                            rel="noreferrer"
                            className="linknavbar"
                          >
                            <li className="nav-link">
                              <span className="navlink">
                                <a className="linknavbar">
                                  <b className={` text-light `}>
                                  TESTE GRÁTIS
                                  </b>
                                </a>
                              </span>
                            </li>
                          </a>
                        </ul>

                        <button onClick={handleVoltarClick}>
                          <ul className={`menu-links text-light `}>
                            <li className="nav-link">
                              <span className="navlink">
                                <a className="linknavbar">
                                  <b className={` text-light `}>SAIR</b>
                                </a>
                              </span>
                            </li>
                          </ul>
                        </button>
                      </div>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
            ))}
          </>

          <div className="container-center">
            <Routes>
              <Route
                path={"/"}
                element={
                  <>
                    <Pagamentos
                      userData={userData}
                      user={userData}
                      setIdinitialization={setIdinitialization}
                      Idinitialization={Idinitialization}
                      setAmountinitialization={setAmountinitialization}
                      DateMsg={DateMsg}
                      setDateMsg={setDateMsg}
                    />
                  </>
                }
              />

              <Route
                path={"/redesociais"}
                element={<RedeSociaisComponent statusMode={statusMode} />}
                s
              />
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderPagamentos;
