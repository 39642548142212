import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Col from "react-bootstrap/Col";
import { IconYoutube } from "../global/ExternalIcon";
import FilterMobile from "./FilterMobile";
import LayoutSurebetLiveDesktop from "./componentSubetLive/LayoutSurebetLiveDesktop";
import { orderGameByTime, notifyBet, openPopUpCalculator, openPopUpAllBet, handleScroll, detectortDiference, filterBetHome } from "./functions/FunctionsGames";
import { modelPopUpSettings, urlSurebetLive, linkChangePlan, linkWhatsapp  } from "../global/GlobalVariables";
import IconExtension from '../assets/img/extension.png';
import IconDollar from '../assets/img/cifrao.png';
import Cookies from 'js-cookie';

function AoVivo(props) {
  const {
    controllerLocalStorage,
    valueMin,
    setValueMin,
    valueMax,
    setValueMax,
    betHome,
    setBetHome,
    quantityBetHomeEnabled,
    setQuantityBetHomeEnabled,
    sports,
    setSports,
    quantitySportsEnabled,
    setQuantitySportsEnabled,
    timeEvent,
    setTimeEvent,
    setlistBehomeEnableds,
    
    orderloop,
    openPopUpBet,
    handleLinkClick,
    handleClick,
    statusMode,
    user,
    filterOrder,
    setFilterOrder, 
    requiredBetHome,
    setRequiredBetHome,
    notificationVolume,
    setentradasLigth,
    entradasLigth,
    setentradasPro,
    entradasPro,
    AparecerProf,
    AparecerVivo,
    AparecerLight,
    AparecerPro
  } = props;

  
  const timeObject = {
    "6 Horas": 60 * 1000 * 60 * 6,
    "12 Horas": 60 * 1000 * 60 * 12,
    "16 Horas": 60 * 1000 * 60 * 16,
    "1 Dia": 60 * 1000 * 60 * 24,
  };
  const [fullData, setFullData] = useState([]);
  const [apitofisrt, setApitoFirst] = useState(false);
  const iconelixeira = `${process.env.PUBLIC_URL}/images/iconlixeira.png`;
  
  const [dataJSON, setDataJSON] = useState( JSON.stringify({}) );

  const [newKeySymbol, setNewKeys] = useState([]);
  const oldData = useRef({});
  const [statusTrash, setStatusTrash] = useState(false);
  const [itemsTrash, setItemsTrash] = useState([]);
  const [settingsPopUpsJSON, setSettingsPopUpsJSON] = useState(localStorage.getItem('popUpSettings') !== null ? localStorage.getItem('popUpSettings') : JSON.stringify(modelPopUpSettings));
  const [ savedSubetsLive, setSavedSubetsLive] = useState( JSON.stringify({}) );
  const [ keySavedSubetsLive, setKeySavedSubetsLive] = useState( '' );
  
  const [warningTimeSurebet, setWarningTimeSurebet] = useState([]);
  const rowRef = useRef(null);
  const audioRef = useRef(null);
  const timePlaySound = useRef(0);
  const soundVolume = useRef(0);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(30);
  const limitSurebetToShow = useRef();

  let data = JSON.parse(dataJSON);
  let dataKeys = Object.keys(data);
  
  function eventTrashLiveOne(nameEvente, statusEvent) {

    

    if (statusEvent === true) {
      setItemsTrash((current) => [...current, nameEvente]);
    } else {
      setItemsTrash(itemsTrash.filter((item) => item !== nameEvente));
    }
    
  }

  function eventTrashLiveAll(nameEvente, statusEvent) {

    

    let dataSurebetSelectedd = data[nameEvente]["main"];

    const idMarkets = dataSurebetSelectedd["id_markets"];

    let keysWithSameEvent = [];

    for(let i = 0; i < dataKeys.length; i++){
      let keySurebetLive = dataKeys[i];
      let dataSurebetLive = data[keySurebetLive]["main"];
      let idMarketsSurebetLive = dataSurebetLive["id_markets"];

      
      let filterMarkets = idMarketsSurebetLive.filter(item => idMarkets.includes(item))
      
      if(filterMarkets.length > 0){
        keysWithSameEvent.push(keySurebetLive);
      }
    }
    
    if (statusEvent === true) {
      setItemsTrash((current) => [...current, ...keysWithSameEvent]);
    } else {
      setItemsTrash(itemsTrash.filter((item) => keysWithSameEvent.includes(item) === false));
    }
    
  }



  function saveSubetLive(key){
    

    let surebetSaved = {};
    surebetSaved[key] = data[key];
    surebetSaved[key]["isPaused"] = true; 
    setKeySavedSubetsLive(key);


    const newSavedSubetsLive = JSON.stringify(surebetSaved);
    setSavedSubetsLive(newSavedSubetsLive);
    orderGameByTime(filterOrder, data, setDataJSON, key);
  }

  function deleteSubetLive(key){
    let actualSavedSubetsLive = JSON.parse(savedSubetsLive);
    delete actualSavedSubetsLive[key];    
    setKeySavedSubetsLive("");
    const newSavedSubetsLive = JSON.stringify(actualSavedSubetsLive);
    setSavedSubetsLive(newSavedSubetsLive);
    orderGameByTime(filterOrder, data, setDataJSON);
  }

  function openMultipleBets(key){
    let listLinks = [];
    let dataSurebet = data[key]["main"];

    

    for(let i = 0; i < orderloop.length; i++){
      let order = orderloop[i];
      if((order in dataSurebet) && "url_bet" in dataSurebet[order] && listLinks.length < 2){
        listLinks.push( dataSurebet[order]["url_bet"] );
      }

    }
    
    if(listLinks.length === 2){
      openPopUpAllBet(listLinks[0], listLinks[1], setSettingsPopUpsJSON, settingsPopUpsJSON);
      openCalculator(key);
    }
  }

  function openCalculator(key){
    openPopUpCalculator(key, data);
  }
  
  const fetchDatas = async (keepSymbolNew=true) => {
    if (oldData.current.value === undefined) {
      oldData.current.value = "{}";
    }

    try {
      const b4726486 = Cookies.get('b4726486')
      const response = await axios.post(
        urlSurebetLive,
        {
          'b652242': b4726486
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        }
      );

      if (!response.status) {
        throw new Error("Falha na requisição");
      }

      const json = await response.data;

      if (JSON.stringify(json) === JSON.stringify(oldData.current.value)) {
        return; // Não há mudanças nos dados, saia da função
      }

      const dataJsonConvertted = json;

      const oldDataJsonConvertted = JSON.parse(oldData.current.value);
      // Verifica os jogos  existentes com o ultimo atualizado

      const newKeys = Object.keys(dataJsonConvertted);
      const oldKeys = Object.keys(oldDataJsonConvertted);

      const storageKeys = newKeys.filter(
        (valueKey) => !oldKeys.includes(valueKey)
      );
      const listKeysToRemove = oldKeys.filter(
        (valueKey) => !newKeys.includes(valueKey)
      );

      listKeysToRemove.forEach((key) => {
        delete oldDataJsonConvertted[key];
      });

      storageKeys.forEach((key) => {
        oldDataJsonConvertted[key] = dataJsonConvertted[key];
      });

      let newListWarningTimeSurebet = detectortDiference(oldDataJsonConvertted);
      setWarningTimeSurebet( newListWarningTimeSurebet );
      let surebetConverted = JSON.parse(savedSubetsLive);
      let valueObjectcombinado = { ...oldDataJsonConvertted, ...surebetConverted };
      setFullData(valueObjectcombinado);


      

      oldData.current.value = JSON.stringify(json);
      if(keepSymbolNew == true){
        let valueFunctionWhistle = notifyBet(soundVolume.current, timePlaySound.current);
        if(valueFunctionWhistle["status"] === true){          
          timePlaySound.current = valueFunctionWhistle["value"];
        }
        setNewKeys((prevArray) => [...prevArray, ...storageKeys]);

        setTimeout(() => {
          setNewKeys((keys) =>
            keys.filter((item) => !storageKeys.includes(item))
          );
        }, 10000);
      }
    } catch (error) {
      console.error("Erro na requisição: " + error.message);
    }
  };
  
  useEffect(() => {

    soundVolume.current = notificationVolume;

    
    if(AparecerVivo === true){
      fetchDatas(false);
      const fetchDataInterval = setInterval(() => {
        fetchDatas();
      }, 1000);

      return () => clearInterval(fetchDataInterval);
    }

    
  }, []);

  useEffect(() => {
    let newValueData = {};

    if (Object.keys(fullData).length > 0) {
      for (let keyObject in fullData) {
        let valueObject = fullData[keyObject];
        let newValueDataObject = filterBetHome(valueObject, betHome, requiredBetHome);

        if (Object.keys(newValueDataObject).length === 0) {
          continue;
        }

        let profit = parseFloat(newValueDataObject["main"]["profit"]);

        if (!(valueMin <= profit && profit <= valueMax)) {
          if (valueMax < 0) {
            setValueMax(valueMax * -1);
          }
          continue;
        } else if (timeEvent !== "Qualquer Horário") {
          let jumpLoop = false;

          for (let i = 0; i < orderloop.length; i++) {
            let order = orderloop[i];

            if (order in newValueDataObject["main"]) {
              let timeGame = parseInt(
                newValueDataObject["main"][order]["time_milliseconds"]
              );
              let timeCalculated = timeGame - new Date().getTime();

              if (timeCalculated >= timeObject[timeEvent]) {
                jumpLoop = true;
                break;
              }
            }
          }

          if (jumpLoop) {
            continue;
          }
        }

        if (itemsTrash.includes(keyObject) === !statusTrash) {
          continue;
        }

        // Verifique se a aposta é habilitada e notificada

        newValueData[keyObject] = newValueDataObject;
      }

      
      let surebetConverted = JSON.parse(savedSubetsLive);
      
      

    
      let keySurebetLiveSaved = keySavedSubetsLive;

      if(keySurebetLiveSaved in fullData){
        fullData[keySurebetLiveSaved]["isPaused"] = true;
        surebetConverted[keySurebetLiveSaved] = fullData[keySurebetLiveSaved];
      }

      if (itemsTrash.includes(keySurebetLiveSaved) !== !statusTrash && keySurebetLiveSaved !== "") {
        newValueData[keySurebetLiveSaved] = surebetConverted[keySurebetLiveSaved];
      }

      let listTimeDiferenceSurebetSaved = detectortDiference(surebetConverted);
      setWarningTimeSurebet( (current) => [...current, ...listTimeDiferenceSurebetSaved] );
      setSavedSubetsLive(JSON.stringify(surebetConverted));
      orderGameByTime(filterOrder, newValueData, setDataJSON, keySavedSubetsLive)

      if (!apitofisrt) {
        setTimeout(() => {
          setApitoFirst(true);
        }, 10000);
      }
    }
    soundVolume.current = notificationVolume;
    localStorage.setItem("trash", JSON.stringify(itemsTrash));
    
  }, [
    fullData,
    valueMin,
    valueMax,
    betHome,
    sports,
    timeEvent,
    itemsTrash,
    statusTrash,
  ]);


  useEffect(()=>{
    
      const dataSurebetLiveMixed = { ...data};
    orderGameByTime(filterOrder, dataSurebetLiveMixed, setDataJSON, keySavedSubetsLive)
  }, [filterOrder]);
  
  useEffect(() => {
    const actualKeysSurebet = Object.keys( JSON.parse(dataJSON) );
    const quantitySurebet = actualKeysSurebet.length;




    if (maxIndex > quantitySurebet && maxIndex > 30) {


      let countLastIndex = quantitySurebet - 30;
      let lastIndex = countLastIndex < 0 ? 0 : countLastIndex;

      setMinIndex(lastIndex);
      setMaxIndex(quantitySurebet);
    } else if (maxIndex < 30) {
      setMinIndex(0);
      setMaxIndex(30);
    }



    limitSurebetToShow.current = quantitySurebet;
    // eslint-disable-next-line
  }, [dataJSON]);  

  
  useEffect(() => {
    limitSurebetToShow.current = 30;
    window.addEventListener('scroll', (event) => handleScroll(rowRef, setMinIndex, setMaxIndex, limitSurebetToShow), { passive: true });
    let overflowBody = document.body.style.overflow;
    if (overflowBody === 'hidden') {
      document.body.style.overflow = "";

    }

    return () => {
      window.removeEventListener('scroll', (event) => handleScroll(rowRef, setMinIndex, setMaxIndex, limitSurebetToShow));
    };
  }, []);

  return (
    <div>
      <div>
      <audio src={`${process.env.PUBLIC_URL}/apitodefutebol.mp3`} preload="auto" />
      
    </div>



      <Col>
        {/* Inicio Calculadore/Lideira e Filtro*/}
        
         <div
          className="py-3 d-flex justify-content-between align-items-center"
          style={{ backgroundColor: statusMode ? "#191c1f" : "#eceff3" }}
        >
          <div className="d-flex">
            <button
              type="button"
              className="btn-calculator mx-3 d-flex justify-content-center align-items-center btn btn-warning fontSizeDefaultDevice btn-p-mobile"
              onClick={() => {
                window.open("/calculator/", "", "width=800,height=460");
              }}
            >
              <p className="font-size-calculator-trash m-0 style-text-calculator-mobile">Calculadora</p>
              <img

                className="img-listagem-topo"
                width="25"
                height="25"
                src="https://img.icons8.com/color/48/calculator--v1.png"
                alt="calculator--v1"
              />
            </button>
            <p className="m-0"></p>

            <button
              type="button"
              className={`btn ${statusTrash ? "btn-primary" : "btn-danger"
                } btn-p-mobile`}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                onClick={() => setStatusTrash(!statusTrash)}
              >


                {statusTrash ? (
                  <>
                    <img
                      style={{
                        filter: "invert(100%)",
                        width: 15,
                        height: 15,
                      }}
                      src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                      alt="left2"
                      className='icon-back-trash'
                    />
                    <p className="m-0 font-size-calculator-trash">
                      Voltar aos jogos
                    </p>
                  </>
                ) : (
                  <>
                    <p className="m-0 font-size-calculator-trash">
                      Lixeira
                    </p>
                    <img
                      className="img-listagem-topo icon-trash"
                      width="27"
                      height="27"
                      src={iconelixeira}
                      alt="delete"
                    />
                  </>
                )}
              </div>
            </button>

            <button
              id="back_game"
              type="button"
              className="h-50 btn  btn-primary d-none"
            >
              <div className="d-flex justify-content-center align-items-center">
                <img
                  style={{
                    filter: "invert(100%)",
                    width: 25,
                    height: 25,
                  }}
                  src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                  alt="left2"
                />
                <p className="m-0 font-size-calculator-trash">Voltar aos jogos</p>
              </div>
            </button>
            <div>

            </div>


            <a class=" d-flex alig-items-center btn-pop-up-redirect" href="https://youtu.be/Ddxg8PsqI30" target="_blank" rel="noopener noreferrer">
              <div class="linknavbar d-flex"><b class="text-light d-flex align-items-center fontSizeDefaultDevice">POP-UPS </b>

              </div>
              <div className='icon-video-pop-up'>
                <IconYoutube />
              </div>
            </a>

            <a href='/extensao' type="button" class="text-light ms-3 fw-bold d-flex align-items-center container-button-extension button-extension-desktop">
              <p className='p-0 m-0'> Extensão</p>
              <img className='ms-2 icon-extension' src={IconExtension} alt="" />
            </a>

            <a href='https://t.me/arbbonusfree' target='_blank' type="button" class="text-light ms-3 fw-bold d-flex align-items-center container-button-bonus button-bonus-desktop">
              <p className='p-0 m-0'>BÔNUS</p>
              <img className='ms-2 icon-extension' src={IconDollar} alt="" />
            </a>

            {
              !statusTrash ?

                <>
                  <a href='/extensao' type="button" class="text-light ms-3 fw-bold d-flex align-items-center container-button-extension button-extension-mobile">
                    <p className='p-0 m-0'> Extensão</p>
                    <img className='ms-2 icon-extension' src={IconExtension} alt="" />
                  </a>

                  <a href='https://t.me/arbbonusfree' target='_blank' type="button" class="text-light ms-3 fw-bold d-flex align-items-center container-button-bonus button-bonus-mobile">
                    <p className='p-0 m-0'>BÔNUS</p>
                    <img className='ms-2 icon-extension' src={IconDollar} alt="" />
                  </a>
                </>
                :
                ""
            }



          </div>

          <div className="me-3">

            <FilterMobile
              statusMode={statusMode}
              controllerLocalStorage={controllerLocalStorage}
              valueMin={valueMin}
              setValueMin={setValueMin}
              valueMax={valueMax}
              setValueMax={setValueMax}
              betHome={betHome}
              setBetHome={setBetHome}
              quantityBetHomeEnabled={quantityBetHomeEnabled}
              setQuantityBetHomeEnabled={setQuantityBetHomeEnabled}
              sports={sports}
              setSports={setSports}
              quantitySportsEnabled={quantitySportsEnabled}
              setQuantitySportsEnabled={setQuantitySportsEnabled}
              timeEvent={timeEvent}
              setTimeEvent={setTimeEvent}
              setlistBehomeEnableds={setlistBehomeEnableds}
              setentradasLigth={setentradasLigth}
              entradasLigth={entradasLigth}
              setentradasPro={setentradasPro}
              entradasPro={entradasPro}
              AparecerProf={AparecerProf}
              AparecerVivo={AparecerVivo}
              AparecerLight={AparecerLight}
              AparecerPro={AparecerPro}
              filterOrder={filterOrder}
              setFilterOrder={setFilterOrder}
              requiredBetHome={requiredBetHome}
              setRequiredBetHome={setRequiredBetHome}
            />
          
        </div>

          
        </div>
        {/* Fim Calculadore/Lideira e Filtro*/}
        {/* Inicio Corpo da Tabela */}
        {
          betHome.includes('novibet')
          ?
          <div className='w-100 bg-danger text-center fw-bold text-light wanrning-danger-bet-home'>
            NoviBet selecionada! Tome cuidado. ⚠️ 
          </div>
          :
          ""
        }
        {
        
        AparecerVivo === false ?
            <div className={`style-container-undisponible-plan ${statusMode ? 'style-bg-dark-container-undisponible-plan' : ''}`}>
              <p className={`text-center style-fs-1 ${statusMode ? 'text-light' : ''}`}>Opss.. Os mercados ao vivo estão indisponíveis para o seu plano. <br />
                Para acessá-los, mude seu plano ou entre em contato com o suporte:
              </p>

              <a href={linkChangePlan} className="btn btn-danger style-fs-4 text-light">
                Mudar de plano
              </a>

              <a href={linkWhatsapp} target="_blank" className="btn btn-success style-fs-4 mt-4">
                Contatar Suporte via WhatsApp
              </a>
            </div>
        :
        dataKeys.length > 0 || statusTrash ? (
          <div className="w-100">



          <div className=" greenArbitragem p-0 header-table d-flex">
            <div className="d-flex w-100 align-items-center">
              <div className={`fw-bold fontSizeDefaultDevice text-center ${statusMode ? "textDark" : "text-light"} p-0  area-profit `} >Lucro</div>
              <div className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"} bedivome profitDesktop area-bet-home area-profit-desktop `} style={{ whiteSpace: "nowrap" }} >Casa de aposta</div>
              <div className={`fw-bold fontSizeDefaultDevice text-center ${statusMode ? "textDark" : "text-light"} p-0 area-date-time`} >Data</div >
              <div className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"} p-0 area-event`} >Evento</div>
              <div className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"} p-0 area-data-market`}    >Mercado</div>
              <div className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"} p-0 area-chance`} >Chance</div>
            </div>
            <div className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"} p-0 area-redirect`} ></div>
          </div>


          <div className="container-sb">
            {





              dataKeys.map((value, index) => {

                return (

                  (index >= minIndex && index <= maxIndex) ?
                    <React.Fragment key={index}>
                      <LayoutSurebetLiveDesktop
                        data={data}
                        value={value}
                        index={index}
                        statusMode={statusMode} 
                        openPopUpCalculator={openCalculator}
                        newKeySymbol={newKeySymbol}
                        statusTrash={statusTrash}
                        iconelixeira={iconelixeira}
                        rowRef={rowRef}
                        handleClick={openMultipleBets}
                        openPopUpBet={openPopUpBet}
                        warningTimeSurebet={warningTimeSurebet}
                        saveSubetLive={saveSubetLive}
                        eventTrashLiveOne={eventTrashLiveOne}
                        eventTrashLiveAll={eventTrashLiveAll}
                        deleteSubetLive={deleteSubetLive}
                      />
                    </React.Fragment>
                     :
                     <div ref={(el) => {
                       rowRef.current = el;
                     }} className={`w-100 ${statusMode ? "bg-sb-dark" : "bg-sb"}`} key={index}>

                     </div>
                   
                )
              }
              )

            }
          </div>
        </div> 
        ) : (
          <>
            <style>{`
        body
        #containerFindBet{
            margin:0;
            padding:0;
            background:#262626;
            height: 600px
          }
          .ring{
            line-height: 1.5!important;
            display: flex;
            justify-content: center;
            align-items: center;
            position:relative;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            width:380px;
            height:380px;
            background:transparent;
            border:3px solid #3c3c3c;
            border-radius:50%;
            text-align:center;
            line-height:150px;
            font-family:sans-serif;
            font-size:20px;
            color:#fff000;
            letter-spacing:4px;
            text-transform:uppercase;
            text-shadow:0 0 10px #fff000;
            box-shadow:0 0 20px rgba(0,0,0,.5);
          }
          .ring:before{
            content:'';
            position:absolute;
            top:-3px;
            left:-3px;
            width:100%;
            height:100%;
            border:3px solid transparent;
            border-top:3px solid #fff000;
            border-right:3px solid #fff000;
            border-radius:50%;
            animation:animateC 2s linear infinite;
          }
          #spinnerAnimation{
            display:block;
            position:absolute;
            top:calc(50% - 2px);
            left:50%;
            width:50%;
            height:4px;
            background:transparent;
            transform-origin:left;
            animation:animate 2s linear infinite;
          }
          #spinnerAnimation:before{
            content:'';
            position:absolute;
            width:16px;
            height:16px;
            border-radius:50%;
            background:#fff000;
            top:-6px;
            right:-8px;
            box-shadow:0 0 20px #fff000;
          }
          @keyframes animateC{
            0%
            {
              transform:rotate(0deg);
            }
            100%
            {
              transform:rotate(360deg);
            }
          }
          @keyframes animate{
            0%
            {
              transform:rotate(45deg);
            }
            100%
            {
              transform:rotate(405deg);
            }
          }
            @media (max-width: 500px){ 
            .ring{
                
                width:calc((350/500) * 100vw)!important;
                height:calc((350/500) * 100vw)!important;
            }                    
          }
        `}</style>

            <div id="containerFindBet">
              <div className="ring">
                Procurando
                <br />
                por
                <br />
                apostas
                <span id="spinnerAnimation"></span>
              </div>
            </div>
          </>
        )}
        {/* Fim Corpo da Tabela */}
      </Col>
    </div>
  );
}

export default AoVivo;
