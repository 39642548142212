import React, { useEffect } from 'react';
import videoRedirectic from '../assets/video/redirect.mp4';
 

function Redirect(props) {
  const {
    dataMakers,
    dataLinks,
    dataArb,

  } = props;
  useEffect(() => {
      setTimeout(()=>{
        let widthScreen = window.innerWidth;
        let urlBet = dataArb['url_bet'];

        if (widthScreen <= 785) {
          const nameBetHomeLower = dataArb["name_bet_home"].toLowerCase();
          if (nameBetHomeLower === "pinnacle") {

            const decodedString = decodeURIComponent(dataMakers);
            console.log(JSON.parse(decodedString))

            const decodedJSON = JSON.parse(decodedString);

            if (("eventId" in decodedJSON) && ("twinsData" in decodedJSON) && ("cid" in decodedJSON)) {


              const sport_pinnacle_lower = decodedJSON["twinsData"].toLowerCase()
              const id_pinnacle_bet = decodedJSON["eventId"]
              const id_league_pinnacle = decodedJSON["cid"]
              const link_pinnacle_mobile = `https://sports.pinnacle.bet.br/m/en/asian/sports/${sport_pinnacle_lower}/All/Matches/0?leagueId=${id_league_pinnacle}&eventId=${id_pinnacle_bet}`;

              
              urlBet = link_pinnacle_mobile
            }
          }
        }

        window.location.href = urlBet;
      }, 3000);
  }, []);


  return ( 
    <div>
      <style>
        {`
          body{
            overflow: hidden;
            background-color: #2b950d;
          }
        `}
      </style>
      <div id="navigation" data-markers={dataMakers} data-links={dataLinks}>
      </div>
      
      <div>
      <video className='video-background'  autoPlay muted loop>
        <source src={videoRedirectic} type="video/mp4" />
        Seu navegador não suporta a tag de vídeo.
      </video>
    </div>
    </div>
  );
}


export default Redirect;