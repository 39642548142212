import '../assets/css/style.css';
import IconExtension from '../assets/img/extension.png';
import React, { useState, useRef, useEffect } from "react";
import Col from "react-bootstrap/Col";
import ComponentSurebet from './ComponentSurebet';
import { orderLoop } from '../global/GlobalVariables';
import { IconYoutube } from '../global/ExternalIcon';
import FilterMobile from './FilterMobile';
import IconDollar from '../assets/img/cifrao.png';

function PreEvento(props) {
  const {
    statusTrash,
    setStatusTrash,
    iconelixeira,
    controllerLocalStorage,
    valueMin,
    setValueMin,
    valueMax,
    setValueMax,
    betHome,
    setBetHome,
    quantityBetHomeEnabled,
    setQuantityBetHomeEnabled,
    sports,
    setSports,
    data,
    quantitySportsEnabled,
    setQuantitySportsEnabled,
    timeEvent,
    setTimeEvent,
    setlistBehomeEnableds,
    newKeySymbol,
    openPopUpCalculator,
    eventTrash,
    openPopUpBet,
    handleClick,
    statusMode,
    setentradasLigth,
    entradasLigth,
    setentradasPro,
    entradasPro,
    AparecerProf,
    AparecerVivo,
    AparecerLight,
    AparecerPro,
    notifyBet,
    filterOrder,
    setFilterOrder,
    warningTimeSurebet,
    requiredBetHome,
    setRequiredBetHome,
  } = props;

  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(30);


  const [dataSurebetModified, setDataSurebetModified] = useState({});
  const limitSurebetToShow = useRef();
  const oldKeysSurebet = useRef([]);

  const rowRef = useRef(null);



  const handleScroll = () => {

    const scrollTop = window.scrollY;




    if (rowRef.current) {
      const rowHeight = rowRef.current.getBoundingClientRect().height;



      let myCount = scrollTop - 270;
      let resultToPut = parseInt(myCount / rowHeight);

      let mx = 30 + resultToPut;
      if (limitSurebetToShow.current >= mx) {

        setMinIndex(resultToPut);
        setMaxIndex(mx);
      } else {

        let newMindIndex = limitSurebetToShow.current - 30;
        let newMindIndexFiltered = newMindIndex < 0 ? 0 : newMindIndex;
        setMinIndex(newMindIndexFiltered);
        setMaxIndex(limitSurebetToShow.current);
      }
    }
  };


  function checkKeyIsInEvent(eventSurebet,  keysEventSurebet){
    const keySurebetEvent = Object.keys(eventSurebet);
    const eventsId = eventSurebet[keySurebetEvent[0]];
    for(let i = 0; i < keysEventSurebet.length; i++){
      let keyEventSurebet = keysEventSurebet[i];
      if(eventsId.includes(keyEventSurebet) === true){
        return true
      }
    }
    return false;
  }

  function eventTrashAllGamesEventsSelected(nameEvente) {

    const dataSurebetSelected = dataSurebetModified[nameEvente]["main"];
    
    let listEvents = [];

    for(let i_order = 0; i_order < orderLoop.length; i_order++){
      let order = orderLoop[i_order];      
      if(order in dataSurebetSelected){
        const eventSelectedByOrder = dataSurebetSelected[order];
        const eventModified = eventSelectedByOrder["title_event"].toLowerCase().replaceAll("-", "").replaceAll("–", "") .replaceAll(" ", "");        
        listEvents.push(eventModified);
      }
    }
    
    
    const allKeysSurebet = Object.keys(data).map(item => {
      const dataSurebetSelected2 = data[item]["main"];
      let listEvents2 = [];
      for (let i_order = 0; i_order < orderLoop.length; i_order++) {
        let order = orderLoop[i_order];



        if (order in dataSurebetSelected2) {
          const eventSelectedByOrder = dataSurebetSelected2[order];
          const eventModified = eventSelectedByOrder["title_event"].toLowerCase().replaceAll("-", "").replaceAll("–", "").replaceAll(" ", "");
          listEvents2.push(eventModified);
        }
      }
      const eventsWithKeySurebet = {}
      eventsWithKeySurebet[item] = listEvents2
      return eventsWithKeySurebet;
    });
    
    const listEventFiltered = allKeysSurebet.filter(item => ( checkKeyIsInEvent(item, listEvents)));
    const listKeysFiltered = listEventFiltered.map(item => Object.keys(item)[0]);

    for(let i = 0; i < listKeysFiltered.length; i++){
      let keyFiltered = listKeysFiltered[i];      
      eventTrash(keyFiltered, statusTrash);
    }
    
  } 


  useEffect(() => {
    const actualKeysSurebet = Object.keys(data);
    const quantitySurebet = actualKeysSurebet.length;




    if (maxIndex > quantitySurebet && maxIndex > 30) {


      let countLastIndex = quantitySurebet - 30;
      let lastIndex = countLastIndex < 0 ? 0 : countLastIndex;

      setMinIndex(lastIndex);
      setMaxIndex(quantitySurebet);
    } else if (maxIndex < 30) {
      setMinIndex(0);
      setMaxIndex(30);
    }



    orderByTime(filterOrder, data);


    for (let i = 0; i < actualKeysSurebet.length; i++) {
      let keySurebet = actualKeysSurebet[i];

      if (oldKeysSurebet.current.includes(keySurebet) === false && newKeySymbol.includes(keySurebet) === true) {
        notifyBet(keySurebet);
        break
      }
    }

    oldKeysSurebet.current = actualKeysSurebet;


    limitSurebetToShow.current = quantitySurebet;
    // eslint-disable-next-line
  }, [data]);



  function orderByTime(statusFilter, dataSurebet) {
    if (statusFilter === 'início') {

      let newDataSurebet = {};
      let keysObject = Object.keys(dataSurebet);
      let gcc = {};
      for (let i = 0; i < keysObject.length; i++) {

        let keyObject = keysObject[i];
        let valueObject = dataSurebet[keyObject]['main'];
        let listLargeTime = [];

        for (let i_orderLoop = 0; i_orderLoop < orderLoop.length; i_orderLoop++) {
          let order = orderLoop[i_orderLoop];

          if (order in valueObject) {


            listLargeTime.push(valueObject[order]['time_milliseconds']);

          }
        }

        let listLargeTimeOrdenated = listLargeTime.sort();
        listLargeTimeOrdenated.reverse()

        if (gcc[listLargeTimeOrdenated[0]] === undefined) {
          gcc[listLargeTimeOrdenated[0]] = [keyObject];
        } else {
          gcc[listLargeTimeOrdenated[0]].push(keyObject);
        }
      }


      let keysGcc = Object.keys(gcc);
      let keysGccOrdenated = keysGcc.sort();

      for (let i = 0; i < keysGccOrdenated.length; i++) {
        let keyGccOrdenated = keysGccOrdenated[i];

        for (let i_gccItem = 0; i_gccItem < gcc[keyGccOrdenated].length; i_gccItem++) {
          let item = gcc[keyGccOrdenated][i_gccItem];
          newDataSurebet[item] = dataSurebet[item];
        }
      }


      setDataSurebetModified(newDataSurebet);
    }
    else if (statusFilter === 'recentes') {

      let newDataSurebet = {};
      let keysObject = Object.keys(dataSurebet);
      let gcc = {};
      let keysNumber = [];
      for (let i = 0; i < keysObject.length; i++) {

        let keyObject = keysObject[i];
        let valueObject = dataSurebet[keyObject]['main'];
        let timeLower = valueObject['time'].toLowerCase();

        let g = timeLower.indexOf('h') !== -1
          ? (parseFloat(timeLower.replace('h', '')) * 60)
          : timeLower.indexOf('dia') !== -1 ?
            (parseFloat(timeLower.replace('dias', '').replace('dia', '')) * 1440)

            : parseFloat(timeLower.replace('min', ''));


        if (gcc[g] === undefined) {
          gcc[g] = [keyObject];
          keysNumber.push(g);
        } else {
          gcc[g].push(keyObject);
        }
      }

      let keysGccOrdenated = keysNumber.sort((a, b) => a - b);


      for (let i = 0; i < keysGccOrdenated.length; i++) {
        let keyGccOrdenated = keysGccOrdenated[i].toString();

        for (let i_gccItem = 0; i_gccItem < gcc[keyGccOrdenated].length; i_gccItem++) {
          let item = gcc[keyGccOrdenated][i_gccItem];
          newDataSurebet[item] = dataSurebet[item];
        }
      }

      setDataSurebetModified(newDataSurebet);
    }
    else {
      setDataSurebetModified(dataSurebet);
    }
  }

  useEffect(() => {
    orderByTime(filterOrder, data);
    // eslint-disable-next-line
  }, [filterOrder]);

  useEffect(() => {
    limitSurebetToShow.current = 30;
    window.addEventListener('scroll', handleScroll, { passive: true });
    let overflowBody = document.body.style.overflow;
    if (overflowBody === 'hidden') {
      document.body.style.overflow = "";

    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Col>
        {/* Inicio Calculadore/Lideira e Filtro*/}
        <div
          className="py-3 d-flex justify-content-between align-items-center"
          style={{ backgroundColor: statusMode ? "#191c1f" : "#eceff3" }}
        >
          <div className="d-flex">
            <button
              type="button"
              className="btn-calculator mx-3 d-flex justify-content-center align-items-center btn btn-warning fontSizeDefaultDevice btn-p-mobile"
              onClick={() => {
                window.open("/calculator/", "", "width=800,height=460");
              }}
            >
              <p className="font-size-calculator-trash m-0 style-text-calculator-mobile">Calculadora</p>
              <img

                className="img-listagem-topo"
                width="25"
                height="25"
                src="https://img.icons8.com/color/48/calculator--v1.png"
                alt="calculator--v1"
              />
            </button>
            <p className="m-0"></p>

            <button
              type="button"
              className={`btn ${statusTrash ? "btn-primary" : "btn-danger"
                } btn-p-mobile`}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                onClick={() => setStatusTrash(!statusTrash)}
              >


                {statusTrash ? (
                  <>
                    <img
                      style={{
                        filter: "invert(100%)",
                        width: 15,
                        height: 15,
                      }}
                      src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                      alt="left2"
                      className='icon-back-trash'
                    />
                    <p className="m-0 font-size-calculator-trash">
                      Voltar aos jogos
                    </p>
                  </>
                ) : (
                  <>
                    <p className="m-0 font-size-calculator-trash">
                      Lixeira
                    </p>
                    <img
                      className="img-listagem-topo icon-trash"
                      width="27"
                      height="27"
                      src={iconelixeira}
                      alt="delete"
                    />
                  </>
                )}
              </div>
            </button>

            <button
              id="back_game"
              type="button"
              className="h-50 btn  btn-primary d-none"
            >
              <div className="d-flex justify-content-center align-items-center">
                <img
                  style={{
                    filter: "invert(100%)",
                    width: 25,
                    height: 25,
                  }}
                  src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                  alt="left2"
                />
                <p className="m-0 font-size-calculator-trash">Voltar aos jogos</p>
              </div>
            </button>
            <div>

            </div>


            <a class=" d-flex alig-items-center btn-pop-up-redirect" href="https://youtu.be/Ddxg8PsqI30" target="_blank" rel="noopener noreferrer">
              <div class="linknavbar d-flex"><b class="text-light d-flex align-items-center fontSizeDefaultDevice">POP-UPS </b>

              </div>
              <div className='icon-video-pop-up'>
                <IconYoutube />
              </div>
            </a>

            <a href='/extensao' type="button" class="text-light ms-3 fw-bold d-flex align-items-center container-button-extension button-extension-desktop">
              <p className='p-0 m-0'> Extensão</p>
              <img className='ms-2 icon-extension' src={IconExtension} alt="" />
            </a>

            <a href='https://t.me/arbbonusfree' target='_blank' type="button" class="text-light ms-3 fw-bold d-flex align-items-center container-button-bonus button-bonus-desktop">
              <p className='p-0 m-0'>BÔNUS</p>
              <img className='ms-2 icon-extension' src={IconDollar} alt="" />
            </a>

            {
              !statusTrash ?

                <>
                  <a href='/extensao' type="button" class="text-light ms-3 fw-bold d-flex align-items-center container-button-extension button-extension-mobile">
                    <p className='p-0 m-0'> Extensão</p>
                    <img className='ms-2 icon-extension' src={IconExtension} alt="" />
                  </a>

                  <a href='https://t.me/arbbonusfree' target='_blank' type="button" class="text-light ms-3 fw-bold d-flex align-items-center container-button-bonus button-bonus-mobile">
                    <p className='p-0 m-0'>BÔNUS</p>
                    <img className='ms-2 icon-extension' src={IconDollar} alt="" />
                  </a>
                </>
                :
                ""
            }



          </div>

          <div className="me-3">

            <FilterMobile
              statusMode={statusMode}
              controllerLocalStorage={controllerLocalStorage}
              valueMin={valueMin}
              setValueMin={setValueMin}
              valueMax={valueMax}
              setValueMax={setValueMax}
              betHome={betHome}
              setBetHome={setBetHome}
              quantityBetHomeEnabled={quantityBetHomeEnabled}
              setQuantityBetHomeEnabled={setQuantityBetHomeEnabled}
              sports={sports}
              setSports={setSports}
              quantitySportsEnabled={quantitySportsEnabled}
              setQuantitySportsEnabled={setQuantitySportsEnabled}
              timeEvent={timeEvent}
              setTimeEvent={setTimeEvent}
              setlistBehomeEnableds={setlistBehomeEnableds}
              setentradasLigth={setentradasLigth}
              entradasLigth={entradasLigth}
              setentradasPro={setentradasPro}
              entradasPro={entradasPro}
              AparecerProf={AparecerProf}
              AparecerVivo={AparecerVivo}
              AparecerLight={AparecerLight}
              AparecerPro={AparecerPro}
              filterOrder={filterOrder}
              setFilterOrder={setFilterOrder}
              requiredBetHome={requiredBetHome}
              setRequiredBetHome={setRequiredBetHome}
            />
          </div>
        </div>
        {/* Fim Calculadore/Lideira e Filtro*/}
        {/* Inicio Corpo da Tabela */}
        {
          betHome.includes('novibet')
            ?
            <div className='w-100 bg-danger text-center fw-bold text-light wanrning-danger-bet-home'>
              NoviBet selecionada! Tome cuidado. ⚠️
            </div>
            :
            ""
        }
        {Object.keys(dataSurebetModified).length > 0 || statusTrash ?
          <div className="w-100">



            <div className=" greenArbitragem p-0 header-table d-flex">
              <div className="d-flex w-100 align-items-center">
                <div className={`fw-bold fontSizeDefaultDevice text-center ${statusMode ? "textDark" : "text-light"} p-0  area-profit `} >Lucro</div>
                <div className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"} bedivome profitDesktop area-bet-home area-profit-desktop `} style={{ whiteSpace: "nowrap" }} >Casa de aposta</div>
                <div className={`fw-bold fontSizeDefaultDevice text-center ${statusMode ? "textDark" : "text-light"} p-0 area-date-time`} >Data</div >
                <div className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"} p-0 area-event`} >Evento</div>
                <div className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"} p-0 area-data-market`}    >Mercado</div>
                <div className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"} p-0 area-chance`} >Chance</div>
              </div>
              <div className={`fw-bold fontSizeDefaultDevice text-start ${statusMode ? "textDark" : "text-light"} p-0 area-redirect`} ></div>
            </div>


            <div className="container-sb">
              {





                Object.keys(dataSurebetModified).map((value, index) => {

                  return (

                    (index >= minIndex && index <= maxIndex) ?
                      <React.Fragment key={index}>
                        <ComponentSurebet
                          data={dataSurebetModified}
                          value={value}
                          index={index}
                          statusMode={statusMode}
                          eventTrash={eventTrash}
                          openPopUpCalculator={openPopUpCalculator}
                          newKeySymbol={newKeySymbol}
                          statusTrash={statusTrash}
                          iconelixeira={iconelixeira}
                          rowRef={rowRef}
                          handleClick={handleClick}
                          openPopUpBet={openPopUpBet}
                          warningTimeSurebet={warningTimeSurebet}
                          eventTrashAllGamesEventsSelected={eventTrashAllGamesEventsSelected}
                        />
                      </React.Fragment>
                      :
                      <div ref={(el) => {
                        rowRef.current = el;
                      }} className={`w-100 ${statusMode ? "bg-sb-dark" : "bg-sb"}`} key={index}>

                      </div>
                  )
                }
                )

              }
            </div>
          </div> : (
            <>
              <style>{`
        body
        #containerFindBet{
            margin:0;
            padding:0;
            background:#262626;
            height: 600px
          }
          .ring{
            line-height: 1.5!important;
            display: flex;
            justify-content: center;
            align-items: center;
            position:relative;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            width:380px;
            height:380px;
            background:transparent;
            border:3px solid #3c3c3c;
            border-radius:50%;
            text-align:center;
            line-height:150px;
            font-family:sans-serif;
            font-size:20px;
            color:#fff000;
            letter-spacing:4px;
            text-transform:uppercase;
            text-shadow:0 0 10px #fff000;
            box-shadow:0 0 20px rgba(0,0,0,.5);
          }
          .ring:before{
            content:'';
            position:absolute;
            top:-3px;
            left:-3px;
            width:100%;
            height:100%;
            border:3px solid transparent;
            border-top:3px solid #fff000;
            border-right:3px solid #fff000;
            border-radius:50%;
            animation:animateC 2s linear infinite;
          }
          #spinnerAnimation{
            display:block;
            position:absolute;
            top:calc(50% - 2px);
            left:50%;
            width:50%;
            height:4px;
            background:transparent;
            transform-origin:left;
            animation:animate 2s linear infinite;
          }
          #spinnerAnimation:before{
            content:'';
            position:absolute;
            width:16px;
            height:16px;
            border-radius:50%;
            background:#fff000;
            top:-6px;
            right:-8px;
            box-shadow:0 0 20px #fff000;
          }
          @keyframes animateC{
            0%
            {
              transform:rotate(0deg);
            }
            100%
            {
              transform:rotate(360deg);
            }
          }
          @keyframes animate{
            0%
            {
              transform:rotate(45deg);
            }
            100%
            {
              transform:rotate(405deg);
            }
          }
          @media (max-width: 500px){ 
            .ring{
                
                width:calc((350/500) * 100vw)!important;
                height:calc((350/500) * 100vw)!important;
            }                    
          }
        `}</style>

              <div id="containerFindBet">
                <div className="ring">
                  Procurando
                  <br />
                  por
                  <br />
                  apostas
                  <span id="spinnerAnimation"></span>
                </div>
              </div>
            </>
          )}
        {/* Fim Corpo da Tabela */}
      </Col>
    </div>
  );
}

export default PreEvento;
